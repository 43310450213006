.home{
    margin-top:10px;
    height: 94vh;
    background: url('../../assets/img/map.PNG');
    background-repeat: repeat;
}
.button{
    text-align:center;
    /* margin-top:60vh; */
}
.map-button{
    background-color:#41c9f0 !important;
    color:white !important;
}
.abtFont{
    font-size: 18px;
    color: #000 !important;
}
.heading{
    color:#000 !important;
}
.home1{
    margin-top: 20px;
}
.resp-container {
    position: relative;
    overflow: hidden;
    padding-top: 50%;
}
.resp-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}
@media only screen and (max-width: 768px) {
    .resp-container{
        width: 320px;
        height: 320px;
    }
}