    .leaflet-container {
      height: 600px;
      width: 100%;
    }
    #refreshButton {
      position: absolute;
      top: 20px;
      right: 20px;
      padding: 10px;
      z-index: 400;
    }
    @media only screen and (max-width: 768px) {
      .leaflet-container{
        height: 500px;
      }
    }
    .fill
{
    min-height: 100%;
    height: 100%;
    width: 100%;
    min-width: 100%;
}