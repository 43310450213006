.footer{
    position: fixed !important;
    width: 100%;
    left: 0;
    bottom: 0;
}
@media only screen and (max-width: 768px) {
    .footer{
        position: relative !important;
        width: 100%;
    }
  }